<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <el-card>
     <div class="vd_button_group">
      <span v-if="disabled === true">
        <el-button type="success" @click="openEdit" size="small" :disabled="!btn.edit">编辑</el-button>
      </span>
      <span v-if="disabled === false">
      <el-button type="primary" @click="submit('deptForm')" size="small">保存</el-button>
        <el-button @click="closeEdit" size="small">取消</el-button>
      </span>
    </div>
     <el-form ref="deptForm" :model="deptForm" :rules="rules" :disabled="disabled" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="部门名称" prop="dept_name">
            <el-input v-model="deptForm.dept_name" maxlength="10" show-word-limit placeholder="请填写部门名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8" :offset="4">
          <el-form-item label="部门简介" prop="dept_brief">
            <el-input v-model="deptForm.dept_brief" :autosize="{ minRows: 2, maxRows: 6 }" @input="deptForm.dept_brief=helper.keepSpace(deptForm.dept_brief)" maxlength="50" show-word-limit placeholder="请填写部门简介" type="textarea" :rows="1"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <div class="vd_button">
        <el-button type="primary" plain size="small" @click="addRow()">添加</el-button>
        <el-button type="danger" plain size="small" @click="delTeam()">删除</el-button>
      </div>

      <el-table ref="team" :data="deptForm.dept_team_list" border class="vg_mt_16" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="48" align="center"></el-table-column>
        <el-table-column label="序号" width="48" align="center">
          <template slot-scope="scope">
            {{ scope.$index+1 }}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span class="vd_title">小组名称</span>
          </template>
          <template slot-scope="scope">
            <el-form-item label-width="0" :prop="'dept_team_list.'+scope.$index+'.dept_team_name'" :rules="[{required:true}]">
              <el-input v-model="deptForm.dept_team_list[scope.$index].dept_team_name" maxlength="30" show-word-limit placeholder="请填写该部门包含的小组名称（必填）"></el-input>
            </el-form-item>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    </el-card>
  </div>
</template>

<script>
import {get} from "@api/request";
import {post} from "@api/request";
import {deptAPI} from "@api/modules/department";
export default {
  name: 'DeptEdit',
  components: {},
  data() {
    return {
      deptForm: {
        dept_id:'',
        dept_name: '',
        dept_brief: '',
        dept_team_list:[],
      },
      dept_team_copy:[],
      key: 0,
      disabled: true,
      selectionsList: [],
      rules:{
        dept_name:[{required: true, trigger: 'blur', message: ' '}]
      },
      btn:{},
      copyForm:[],
      loadingFlag:true
    }
  },
  watch: {
  },
  created() {
    this.initData()
  },
  methods: {
    //启用编辑
    openEdit(){
      this.disabled = false
      const delTime = this.helper.toTimeVal()
    },
    //取消编辑
    closeEdit(){
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.disabled = true
        this.initData()
        this.$message({
          type: 'info',
          message: '已撤销编辑!'
        });
      })
      .catch(()=>{})
    },
    initData(){
      this.loadingFlag = true
      if(this.$route.query.form_id){
        this.getDeptInfo();
      }
    },
    //提交表单
    submit(formName){
      this.$confirm('确定提交？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            console.log('error submit!');
            return false;
          }
        });
      })
      .catch(()=>{})
    },
    // 保存
    saveInfo() {
      this.deptForm.dept_id = this.$route.query.form_id;
      let deptForm = {};
      let deptFi = [];
      deptForm = Object.assign(deptForm,this.deptForm);
      deptFi = Object.assign(deptFi,this.dept_team_copy);
      let bbj = this.helper.deleteSaveBody(deptForm,deptFi,this.copyForm,2);
      deptFi = deptFi.concat(bbj);
      deptForm.dept_team_list = deptFi;
      post(deptAPI.editDept,deptForm)
      .then(res => {
        if(res.data.code === 0){
          this.$message({
            type:'success',
            message:'保存成功'
          })
          this.initData()
          this.disabled = true
        }else if(res.data.code === 7){
          this.$message({message: '该部门名称已存在', type: 'error'});
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      })
      .catch((res)=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    //添加
    addRow() {
      let item = {
        dept_team_name: '',
        delete_time: 0,
        k: this.key
      }
      this.key = this.key +1
      this.deptForm.dept_team_list.push(item);
    },
    //
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection
    },
    //多选删除
    delTeam() {
       if(this.selectionsList.length>0){
        if(this.deptForm.dept_team_list.length>1 && this.deptForm.dept_team_list.length-this.selectionsList.length>0){
          this.$confirm('确定取消删除?', '提示', {
            confirmButtonText: '是',
            cancelButtonText: '否',
            type: 'warning'
          }).then(() => {
            let defaultDeleted = false;
            for(let i = 0 ; i < this.selectionsList.length; i++) {
              this.deptForm.dept_team_list.forEach((item)=>{
                if(item.k === this.selectionsList[i].k) {
                  if(this.defaultRadio === item.k) {
                    defaultDeleted = true;
                  }
                  let currentIndex = this.deptForm.dept_team_list.indexOf(item);
                  this.deptForm.dept_team_list.splice(currentIndex,1);
                }
              });
            }
            if(defaultDeleted) {
              if(this.deptForm.dept_team_list.length > 0) {
                this.defaultRadio = this.deptForm.dept_team_list[0].k;
              } else {
                this.defaultRadio = this.deptForm.key;
              }
            }
          })
          .catch(()=>{})
        }else{
          this.$message.warning('至少保留一个小组')
        }
      }else{
        this.$message.warning('至少选择一条数据')
      }
    },
    //获取表单信息
    getDeptInfo() {
      this.deptForm.dept_id = this.$route.query.form_id;
      get(deptAPI.getDeptById, {dept_id:this.deptForm.dept_id})
          .then(res => {
            if (res.data.code === 0) {
              this.deptForm = res.data.data.form;
              this.btn = res.data.data.btn
              this.deptForm.dept_team_list.forEach(item=>{
                item.k = this.key;
                this.key++;
              })
              this.dept_team_copy = this.deptForm.dept_team_list;
              this.copyForm = Object.assign(this.copyForm,this.deptForm.dept_team_list)
              setTimeout(() => {
                this.loadingFlag = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          })
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button{
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.vd_title{
  color: $color-deep-red;
}
.vg_wrapper ::v-deep.el-form-item{
  margin: 0;
  padding: 0;
}
.vg_wrapper ::v-deep .el-table td,::v-deep.el-table th{
  padding: 0;
}

.vg_wrapper ::v-deep .el-table .cell {
  padding: 0;
}
::v-deep.is-disabled .el-input__inner {
  cursor: default;
  color: $color-conventional-text;
}
</style>